.PracticalInformationSection {
    @mixin studyPageSectionPadding;
    background: var(--blackLighten60Color);
}

.Title {
    @mixin sectionHeading;
    margin-bottom: 32px;

    @media (--tablet) {
        margin-bottom: 64px;
    }
}

.ContentLink {
    margin-top: 16px;

    @media (--tablet) {
        margin-top: 24px;
    }
}
